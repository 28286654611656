export type TypeErrorTreatment = {
  message: string;
  codigo?: number;
};

const ErrorTreatment = (error: any): TypeErrorTreatment[] => {
  const arrayErros: TypeErrorTreatment[] = [];
  if (error.response) {
    switch (error.response.status) {
      case 400:
        if (typeof error.response.data === 'string') {
          arrayErros.push({
            message: error.response.data,
            codigo: 400,
          });
        } else if (error.response.data && Array.isArray(error.response.data)) {
          error.response.data.forEach((el: any) => {
            arrayErros.push({ message: el.message, codigo: 400 });
          });
        } else {
          arrayErros.push({
            message: 'Ocorreu um erro inesperado',
            codigo: 400,
          });
        }
        break;
      case 403:
        arrayErros.push({ message: 'Sem permissão', codigo: 403 });
        break;
      case 404:
        arrayErros.push({ message: 'Não encontrado ou inválido', codigo: 404 });
        break;
      case 500:
        arrayErros.push({ message: 'Erro interno do servidor', codigo: 500 });
        break;
      default:
        arrayErros.push({ message: 'Ocorreu um erro inesperado' });
        break;
    }
  } else {
    arrayErros.push({ message: 'Erro interno do servidor' });
  }
  return arrayErros;
};

const ErrorFormatString = (error: any, t: any) => {
  let message = '';
  error.forEach((el: TypeErrorTreatment) => {
    message += `${t(el.message)} ${error.length > 1 ? `{'\n'}` : ''}`;
  });
  return message;
};

export { ErrorTreatment, ErrorFormatString };
